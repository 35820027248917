import React, { Component } from 'react';
import {
  Layout,
} from '../components';
import {
  Wrapper,
} from '../components/common';
import './shop.scss';


class ShopHoldingPage extends Component {
  render() {
    const {
      location,
    } = this.props;
    const seoData = {
      metaTitle: `Shop | Women's & Girls' Emergency Centre`,
      metaDescription: '',
      banner: '',
    };
    return (
      <Layout location={location} seoData={seoData}>
        <section className="shop-holding-page">
          <Wrapper isBound>
            <div className="text-container">
              <h1 className="headline">The Shop Will Be Right Back</h1>
              <p className="description">
                We are currently working on our online shop. Please check back soon.
              </p>
            </div>
          </Wrapper>
        </section>
      </Layout>
    );
  }
}

export default ShopHoldingPage;
